import {
  ApolloClient, ApolloProvider, createHttpLink, InMemoryCache
} from "@apollo/client";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Navigate, Route
} from "react-router-dom";
import SlideRoutes from 'react-slide-routes';
import App from './App';
import AssessmentShow from "./components/assessments/AssessmentShow";
import FlightIndex from "./components/flights/FlightIndex";
import FlightNew from "./components/flights/FlightNew";
import FlightShow from "./components/flights/FlightShow";
import FratIndex from './components/frats/FratIndex';
import FratShow from './components/frats/FratShow';
import MitigationsIndex from "./components/mitigations/MitigationsIndex";
import MitigationsNew from "./components/mitigations/MitigationsNew";
import './index.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://47fde15b5dfd413eac25e1a22c21a19f@o524370.ingest.sentry.io/6223012",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.01,
});

const link = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/sms/api/graphql`,
  credentials: 'include'
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link
});

const theme = createTheme({
  typography: {
    h2: {
      fontSize: 64,
      lineHeight: '64px',
      fontWeight: 300
    },
    h3: {
      fontSize: 48,
      lineHeight: '64px',
      fontWeight: 700
    },
    h4: {
      fontSize: 32,
      lineHeight: '32px',
      fontWeight: 300
    },
    body1: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 300
    },
    body2: {
      fontSize: 18,
      lineHeight: '32px',
      fontWeight: 400
    },
    button: {
      fontSize: 18,
      lineHeight: '32px',
      fontWeight: 400
    },
    caption: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 400
    },
    overline: {
      fontSize: 12,
      lineHeight: '12px',
      fontWeight: 400
    }
  },
  palette: {
    // @ts-ignore
    blue: {
      main: '#7294B9'
    },
    greyblue: {
      main: '#B6C2D1'
    },
    darkblue: {
      main: '#536A86'
    },
    greyblue_dark: {
      main: '#656B74'
    },
    green: {
      main: '#1B9A72'
    },
    orange: {
      main: '#FF9B0D'
    },
    red: {
      main: '#FF0000'
    },
    primary: {
      main: '#000000',
      contrastText: '#fff'
    },
    n000: {
      main: '#000000'
    },
    n000a01: {
      main: 'rgba(0, 0, 0, 0.15)'
    },
    n025: {
      main: '#404040'
    },
    n050: {
      main: '#808080'
    },
    n075: {
      main: '#BFBFBF'
    },
    n090: {
      main: '#E5E5E5'
    },
    n100: {
      main: '#FFFFFF'
    },
    bgblur: {
      main: 'rgba(255, 255, 255, 0.8)'
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <SlideRoutes animation="slide">
              <Route path="/" element={<App />}>
                <Route path="" element={<Navigate to="/flights" />} />
                <Route path="/flights" element={<FlightIndex/>} />
                <Route path="/flights/new" element={<FlightNew/>} />
                <Route path="/flights/:flightId" element={<FlightShow/>} />
                <Route path="/flights/:flightId/frats" element={<FratIndex/>} />
                <Route path="/flights/:flightId/frats/:fratId" element={<FratShow/>} />
                <Route path="/flights/:flightId/assessments/:assessmentId" element={<AssessmentShow/>} />
                <Route path="/flights/:flightId/assessments/:assessmentId/mitigations" element={<MitigationsIndex/>} />
                <Route path="/flights/:flightId/assessments/:assessmentId/mitigations/new" element={<MitigationsNew/>} />
                <Route path="/frats" element={<FratIndex/>}/>
                <Route path="/frats/:fratId" element={<FratShow />} />
              </Route>
            </SlideRoutes>
          </BrowserRouter>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
